import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import React from "react";
import { OfferItems } from "./OfferItems";
import { SolarOfferItems } from "./SolarOfferItems";
import { usePlan } from "@Light/utils/plan";

export type OfferProps = {};

export const Offer: React.FC<OfferProps> = ({}) => {
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Button = scaffold.page.button;
  const Link = scaffold.system.link;
  const Hyperlink = scaffold.page.hyperlink;
  const OfferLogo = scaffold.enroll.steps.offerLogo;
  const plan = usePlan();
  const { solarBuyback, vehicleCharging } = plan.features;
  const hasWelcome = Boolean(scaffold.enroll.steps.welcome);

  const legalEntityName =
    scaffold.page.legalEntityName || scaffold.page.companyName;

  return (
    <PageBody
      footer={
        <div className="flex flex-col gap-4">
          {scaffold.page.termsOfServiceUrl && (
            <div
              className={clsx("text-sm", scaffold.page.colors.text.tertiary)}
            >
              <span>By proceeding, you agree to {legalEntityName}'s</span>{" "}
              <span>
                <Hyperlink
                  href={scaffold.page.termsOfServiceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </Hyperlink>
              </span>
            </div>
          )}
          <div className="flex flex-row justify-between items-center gap-4">
            <Link className="w-full" to="/enroll/faq">
              <Button buttonType="outline" className="w-full">
                Learn more
              </Button>
            </Link>
            <Link
              className="w-full"
              to={hasWelcome ? "/enroll/welcome" : "/enroll/service-address"}
            >
              <Button className="w-full">Get your rate</Button>
            </Link>
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-6 pt-10">
        <div className="flex flex-col gap-4 text-center justify-center">
          {OfferLogo ? (
            <div className="flex justify-center">
              <OfferLogo />
            </div>
          ) : (
            <div style={{ height: "48px", width: "48px" }} />
          )}
          <div
            className={clsx(
              "font-medium text-3xl",
              scaffold.page.colors.text.primary,
            )}
          >
            {scaffold.page.companyName} {plan.plan.name}
          </div>
          <div className={clsx(scaffold.page.colors.text.secondary)}>
            Grid electricity for your home
            {vehicleCharging && " and vehicle"}
          </div>
        </div>
        {solarBuyback ? <SolarOfferItems /> : <OfferItems />}
      </div>
    </PageBody>
  );
};
