import { useAPI, useGetConfigurationQuery } from "@/services/api";
import { defaultScaffold } from "@Light/scaffold/default";
import { createContext, useContext, useEffect } from "react";
import { Provider as LightProvider } from "@Light/scaffold/Provider";
import { LightContext } from "@Light/services/light";
import { light } from "@/services/light";
import { lightSandbox } from "@/services/lightSandbox";
import { LightSandboxContext } from "@Light/services/lightSandbox";
import { EmptyHeaderScaffold } from "./EmptyHeader";
import { EnrollmentConfirmedFooter } from "./EnrollmentConfirmedFooter";
import { HeaderLogo } from "./HeaderLogo";
import { SmallLogo } from "./SmallLogo";
import { EnrollmentHeader } from "./EnrollmentHeader";
import { FlowHeader } from "./FlowHeader";
import { useAnalytics } from "@Light/app/analytics";

export type AccountState = ReturnType<typeof useGetConfigurationQuery>;

export const ConfigurationContext = createContext<AccountState | undefined>(
  undefined,
);

export type WithConfigurationProps = {
  children: React.ReactNode;
};

export const WithConfiguration: React.FC<WithConfigurationProps> = ({
  children,
}: WithConfigurationProps) => {
  const { useGetConfigurationQuery } = useAPI();
  const configuration = useGetConfigurationQuery();
  const { setFeatureFlags } = useAnalytics();

  useEffect(() => {
    if (!configuration.isLoading && configuration.data?.feature_flags) {
      setFeatureFlags(configuration.data.feature_flags);
    }
  }, [configuration.data?.feature_flags, setFeatureFlags]);

  if (configuration.isLoading) {
    return null;
  }

  children = (
    <ConfigurationContext.Provider value={configuration}>
      {children}
    </ConfigurationContext.Provider>
  );
  if (!configuration.data) {
    return children;
  }

  const collectPaymentAfterEnrollment =
    configuration.data.feature_flags?.includes(
      "CUSTOMER_CLIENT_PAYMENT_AFTER_ENROLL",
    ) ?? false;

  const headerLogo = configuration.data?.logo ? HeaderLogo : undefined;
  const Header =
    configuration.data?.scope === "enrollment" ? EnrollmentHeader : FlowHeader;
  const scaffold = {
    ...defaultScaffold,
    page: {
      ...defaultScaffold.page,
      companyName: configuration.data.company_name,
      legalEntityName: configuration.data.legal_entity_name,
      termsOfServiceUrl: configuration.data.terms_of_service_url ?? undefined,
      supportEmail: configuration.data.support_email,
      supportPhone: configuration.data.support_phone,
      supportHours: configuration.data.support_hours ?? "",
      header: {
        ...defaultScaffold.page.header,
        header: Header,
        emptyHeader: EmptyHeaderScaffold,
        headerLogo,
      },
    },
    enroll: {
      ...defaultScaffold.enroll,
      steps: {
        ...defaultScaffold.enroll.steps,
        offer: undefined,
        welcome: undefined,
        offerLogo: SmallLogo,
        enrollmentConfirmedFooter: EnrollmentConfirmedFooter,
      },
      collectPaymentAfterEnrollment,
    },
  };
  return (
    <LightProvider scaffold={scaffold}>
      <LightContext.Provider value={light}>
        <LightSandboxContext.Provider value={lightSandbox}>
          {children}
        </LightSandboxContext.Provider>
      </LightContext.Provider>
    </LightProvider>
  );
};

export function useConfiguration() {
  const configuration = useContext(ConfigurationContext);
  if (!configuration || !configuration.isSuccess || configuration.error) {
    return undefined;
  }

  return configuration.data;
}

export function requireConfiguration() {
  const configuration = useConfiguration();
  if (!configuration) {
    throw new Error("configuration required for use with requireConfiguration");
  }

  return configuration;
}
