import React from "react";
import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

export type PageBodyProps = Omit<React.ComponentProps<"div">, "title"> & {
  backLink?: string;
  notification?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  footer?: React.ReactNode;
  progress?: string;
};

export const PageBody: React.FC<PageBodyProps> = React.forwardRef<
  HTMLDivElement,
  PageBodyProps
>(
  (
    {
      backLink,
      notification,
      title,
      subtitle,
      footer,
      progress,
      children,
      className,
    },
    ref,
  ) => {
    const { sizeProps } = useHeader();
    const scaffold = useScaffold();
    const Title = scaffold.page.title;
    const Subtitle = scaffold.page.subtitle;
    const ProgressBar = scaffold.page.progressBar;
    const Link = scaffold.system.link;
    return (
      <div
        {...sizeProps}
        ref={ref}
        className={clsx("flex flex-col", className)}
      >
        {progress ? <ProgressBar progress={progress} /> : null}
        <div
          className={clsx(
            "flex flex-col px-4 pb-4 h-full justify-between gap-8 overflow-y-auto",
          )}
        >
          <div
            className={clsx("flex flex-col gap-8", footer ? null : "h-full")}
          >
            {backLink || notification || title || subtitle ? (
              <div className="flex flex-col gap-8 pt-8">
                {notification ? notification : null}
                <div className="flex flex-col gap-2">
                  {backLink && (
                    <Link
                      to={backLink}
                      className={clsx(
                        "text-sm -mt-6",
                        scaffold.page.colors.text.secondary,
                      )}
                    >
                      <div className="flex flex-row gap-1">
                        <ArrowLeftIcon className="w-4" />
                        <div>Back</div>
                      </div>
                    </Link>
                  )}
                  <div className="flex flex-col gap-2">
                    {title ? <Title>{title}</Title> : null}
                    {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
                  </div>
                </div>
              </div>
            ) : null}
            {children}
          </div>
          {footer ?? null}
        </div>
      </div>
    );
  },
);
