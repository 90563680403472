import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { StatusBox } from "./StatusBox";

export type ErrorBoxProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
};

export const ErrorBox: React.FC<ErrorBoxProps> = ({
  title,
  subtitle,
  children,
}) => {
  const scaffold = useScaffold();
  return (
    <StatusBox
      icon={
        <ExclamationCircleIcon
          className={clsx("w-1/12", scaffold.page.colors.text.error)}
        />
      }
      className={clsx(
        scaffold.page.colors.border.error,
        scaffold.page.colors.background.error,
      )}
      title={title}
      subtitle={subtitle}
    >
      {children}
    </StatusBox>
  );
};
