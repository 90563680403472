import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { useMutation } from "@Light/utils/mutation";
import { useNavigate } from "@Light/utils/context";
import { useLight } from "@Light/services/light";
import { useCallback } from "react";
import { OfferSummary } from "./OfferSummary";
import { ServiceCard } from "../ServiceCard";
import { useAccount } from "@Light/components/page/account";
import { WithLocationPlan, usePlan } from "@Light/utils/plan";
import { useEnrollRoutes } from "../routes";

export type VerifyAndEnrollProps = {};

export const VerifyAndEnroll: React.FC<VerifyAndEnrollProps> = ({}) => {
  return (
    <WithLocationPlan>
      <VerifyAndEnrollB1 />
    </WithLocationPlan>
  );
};

export type VerifyAndEnrollB1Props = {};

export const VerifyAndEnrollB1: React.FC<VerifyAndEnrollB1Props> = ({}) => {
  const account = useAccount();
  const plan = usePlan();
  const { useFinalizeEnrollmentMutation } = useLight();
  const finalizeEnrollment = useMutation(useFinalizeEnrollmentMutation);
  const scaffold = useScaffold();
  const navigate = useNavigate();
  const { previousRoute } = useEnrollRoutes();

  const collectPaymentAfterEnrollment =
    scaffold.enroll.collectPaymentAfterEnrollment;

  const mutate = useCallback(() => {
    if (collectPaymentAfterEnrollment) {
      navigate("/enroll/setup-payment");
      return;
    }

    finalizeEnrollment.mutate();
  }, [finalizeEnrollment.mutate, collectPaymentAfterEnrollment, navigate]);

  if (account.enrollment?.is_enrollment_finalized) {
    const Navigate = scaffold.system.navigate;
    return <Navigate to="/enroll/confirmed" />;
  }

  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  return (
    <PageBody
      backLink={previousRoute("/confirm-and-enroll")}
      title="Confirm and enroll"
      progress={collectPaymentAfterEnrollment ? "50%" : "75%"}
      footer={
        <div className="flex flex-col gap-4">
          <div className={clsx("text-sm", scaffold.page.colors.text.tertiary)}>
            By selecting ‘Enroll,’ you designate Light to perform the necessary
            tasks to initiate your service with the {scaffold.page.companyName}{" "}
            {plan.plan.name} plan.
          </div>
          {collectPaymentAfterEnrollment && (
            <div
              className={clsx("text-sm", scaffold.page.colors.text.tertiary)}
            >
              You authorize Light to charge your payment method on-file on a
              recurring basis each billing cycle for the invoiced amount on the
              due date of each bill. You will receive your bill at least 16 days
              before we debit your account, and you may amend or cancel this
              authorization at any time by providing notice to Light with 30
              days notice.
            </div>
          )}
          <MutationButton
            mutate={mutate}
            mutation={finalizeEnrollment}
            mutateButtonText="Enroll"
            errorMessage="Failed finalizing enrollment. Please try again later."
          >
            Secure my plan
          </MutationButton>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        <ServiceCard />
        <OfferSummary />
      </div>
    </PageBody>
  );
};
