import { QueryLoader } from "@Light/components/app/QueryLoader";
import { isPastDue, paymentDays } from "@Light/components/dash/Billing/common";
import { useInvoiceLink } from "@Light/components/dash/BillingInvoice/common";
import { Subtitle } from "@Light/components/page/Subtitle";
import { SuccessBox } from "@Light/components/page/errors/SuccessBox";
import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { BillingInvoice } from "@Light/services/lightTypes";
import { useState } from "@Light/utils/state";
import dayjs from "dayjs";
import { useMemo } from "react";

const BACK_ROUTE = "/account/payment-method/";

export type PaymentMethodUpdatedProps = {
  hideBack?: boolean;
  backLink?: string;
  returnLink?: string;
};

export const PaymentMethodUpdated: React.FC<PaymentMethodUpdatedProps> = ({
  hideBack,
  backLink,
  returnLink,
}) => {
  const { setTitle, setBackNavigate } = useHeader();
  const { useGetBillingInvoicesQuery } = useLight();
  const billingInvoices = useGetBillingInvoicesQuery();

  setTitle("Payment method updated");
  if (!hideBack) {
    setBackNavigate(backLink || BACK_ROUTE);
  }

  return (
    <QueryLoader queries={[billingInvoices]} allowError>
      <PaymentMethodUpdatedB1
        invoices={billingInvoices.data?.data || []}
        returnLink={returnLink}
      />
    </QueryLoader>
  );
};

export type PaymentMethodUpdatedB1Props = {
  invoices: BillingInvoice[];
  returnLink?: string;
};

export const PaymentMethodUpdatedB1: React.FC<PaymentMethodUpdatedB1Props> = ({
  invoices,
  returnLink,
}) => {
  const now = useState(dayjs()).val;
  const pastDueInvoices = useMemo(
    () =>
      invoices.filter((invoice) => {
        const days = paymentDays(now, invoice);
        return isPastDue(days, invoice);
      }),
    [invoices],
  );
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Link = scaffold.system.link;
  const Button = scaffold.page.button;
  return (
    <PageBody
      className="pt-8"
      footer={
        pastDueInvoices.length > 0 ? (
          <div className="flex flex-col gap-2">
            <Subtitle>
              It looks like you have an unpaid invoice for{" "}
              <span className="font-medium">${pastDueInvoices[0].total}</span>{" "}
              that was due on{" "}
              <span className="font-medium">
                {dayjs(pastDueInvoices[0].payment_due_date).format(
                  "MMM D, YYYY",
                )}
              </span>
              . Click "Pay invoice" below to continue on to pay the invoice.
            </Subtitle>
            <Link
              to={`${useInvoiceLink(pastDueInvoices[0])}make-payment`}
              className="w-full"
            >
              <Button className="w-full">Pay invoice</Button>
            </Link>
          </div>
        ) : (
          <Link to={returnLink || BACK_ROUTE} className="w-full">
            <Button className="w-full">Return</Button>
          </Link>
        )
      }
    >
      <div className="flex flex-col gap-4">
        <SuccessBox subtitle="You've successfully updated your payment method" />
      </div>
    </PageBody>
  );
};
