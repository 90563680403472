import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { InvoiceCharges } from "./InvoiceCharges";
import { useInvoiceLink } from "./common";
import { UpdatePaymentMethodLink } from "@Light/components/account/PaymentMethod/UpdatePaymentMethodLink";
import { useInvoice } from "./context";
import { PayInvoiceNotification } from "./PayInvoiceNotification";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import { PayInvoiceButton } from "./PayInvoiceButton";

export type PayInvoiceProps = {
  hideBack?: boolean;
};

export const PayInvoice: React.FC<PayInvoiceProps> = ({ hideBack }) => {
  const { invoice } = useInvoice();
  const { setBackNavigate } = useHeader();
  const { useGetPaymentMethodQuery } = useLight();
  const paymentMethod = useGetPaymentMethodQuery();
  const invoiceLink = useInvoiceLink(invoice);

  if (!hideBack) {
    setBackNavigate(invoiceLink);
  }

  return (
    <QueryLoader queries={[paymentMethod]} allowError>
      <PayInvoiceB1 />
    </QueryLoader>
  );
};

export type PayInvoiceB1Props = {};

export const PayInvoiceB1: React.FC<PayInvoiceB1Props> = ({}) => {
  const { invoice } = useInvoice();
  const invoiceLink = useInvoiceLink(invoice);

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Navigate = scaffold.system.navigate;
  if (invoice.paid_at || invoice.total_cents <= 0) {
    return <Navigate to={invoiceLink} />;
  }

  return (
    <PageBody
      title={`Pay invoice #${invoice.number}`}
      subtitle="Authorize payment for the selected invoice"
      footer={<PayInvoiceButton />}
    >
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <PayInvoiceNotification />
          <UpdatePaymentMethodLink />
        </div>
        <InvoiceCharges invoice={invoice} />
      </div>
    </PageBody>
  );
};
