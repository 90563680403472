import { useScaffold } from "@Light/scaffold";
import dayjs from "dayjs";
import clsx from "clsx";
import { InvoiceStatusChip } from "./InvoiceStatusChip";
import { useMemo } from "react";
import { InvoiceComparison } from "./InvoiceComparison";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/solid";
import { useHeader } from "@Light/components/page/header/context";
import { InvoiceCharges } from "./InvoiceCharges";
import { useInvoice } from "./context";
import { MakePaymentLink } from "./MakePaymentLink";

const PREVIOUS_ROUTE = "/dash/billing/";

export type BillingInvoiceProps = {};

export const BillingInvoice: React.FC<BillingInvoiceProps> = ({}) => {
  const { invoice, invoices } = useInvoice();
  const { setBackNavigate } = useHeader();
  const { month, year } = useMemo(() => {
    const date = dayjs(invoice.invoice_date);
    const month = date.month();
    const year = date.year();
    return { month, year };
  }, [invoice.invoice_date]);
  const lastMonth = month === 0 ? 11 : month - 1;
  const lastYear = year - 1;
  const lastInvoice = useMemo(
    () =>
      invoices.find((invoice) => {
        const date = dayjs(invoice.invoice_date);
        return date.month() === lastMonth && date.year() === year;
      }),
    [invoices, lastMonth, year],
  );
  const lastYearInvoice = useMemo(
    () =>
      invoices.find((invoice) => {
        const date = dayjs(invoice.invoice_date);
        return date.month() === month && date.year() === lastYear;
      }),
    [invoices, month, lastYear],
  );

  setBackNavigate(PREVIOUS_ROUTE);

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  return (
    <PageBody>
      <div className="flex flex-col gap-6 pt-8">
        <div className="flex flex-col justify-center items-center text-center gap-3 pt-4 pb-3">
          <div className="flex flex-col gap-0.5">
            <div
              className={clsx("text-xs", scaffold.page.colors.text.secondary)}
            >
              {dayjs(invoice.invoice_date).format("MMMM D, YYYY")}
            </div>
            <div
              className={clsx("text-3xl", scaffold.page.colors.text.primary)}
            >
              ${invoice.total}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-4">
            <div className="flex">
              <InvoiceStatusChip
                invoice={invoice}
                className="text-xs font-semibold"
              />
            </div>
            <MakePaymentLink />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex justify-center items-center">
            <div
              className={clsx("text-xs", scaffold.page.colors.text.tertiary)}
            >
              Invoice #{invoice.number}
            </div>
          </div>
          <div className="border rounded flex flex-row justify-between divide-x items-start h-[70px]">
            <InvoiceComparison
              label="This month"
              invoice={invoice}
              dateFormat="MMMM YYYY"
            />
            <InvoiceComparison
              label="Last month"
              invoice={lastInvoice}
              dateFormat="MMMM YYYY"
            />
            <InvoiceComparison
              label="Last year"
              invoice={lastYearInvoice}
              dateFormat="MMMM D, YYYY"
            />
          </div>
        </div>
        <InvoiceCharges invoice={invoice} />
        {invoice.payment_due_date && !invoice.paid_at ? (
          <div
            className={clsx(
              "flex flex-row justify-between",
              "text-blue-950 bg-blue-50 border border-blue-200",
              "rounded px-2 py-4 text-sm",
            )}
          >
            <div>Automatic payment date</div>
            <div>{dayjs(invoice.payment_due_date).format("MMMM D, YYYY")}</div>
          </div>
        ) : null}
        <a href={invoice.pdf} target="_blank" rel="noopener noreferrer">
          <div className="flex flex-row w-full justify-center items-center pb-4">
            <div className="flex flex-row gap-2 items-center text-blue-950 text-sm">
              <div className="">Download PDF</div>
              <ArrowRightEndOnRectangleIcon className="w-6 h-6" />
            </div>
          </div>
        </a>
      </div>
    </PageBody>
  );
};
