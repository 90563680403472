import { useScaffold } from "@Light/scaffold";
import { Routes, Route, Navigate } from "react-router-dom";
import { VehicleAuthorization } from "./VehicleAuthorization";
import { useAccount } from "@Light/components/page/account";
import { useEnrollRoutes } from "./routes";
import { useSearchPreliminaryAddress } from "./preliminaryAddressHook";
import { WithEnrollmentHeader } from "./WithEnrollmentHeader";
import { VerifyAndEnroll } from "./VerifyAndEnroll";
import { UploadInvoice } from "./UploadInvoice";
import { CreditCheck } from "./CreditCheck";

export type EnrollRouteProps = {};
export const EnrollRoute: React.FC<EnrollRouteProps> = () => {
  const scaffold = useScaffold();
  const { hasRoute } = useEnrollRoutes();

  const Offer = scaffold.enroll.steps.offer;
  return (
    <Routes>
      {hasRoute("/offer") && Offer && (
        <Route path="/offer" element={<Offer />} />
      )}
      <Route path="/*" element={<EnrollRouteWithHeader />} />
    </Routes>
  );
};

export type EnrollRouteWithHeaderProps = {};

export const EnrollRouteWithHeader: React.FC<
  EnrollRouteWithHeaderProps
> = ({}) => {
  const scaffold = useScaffold();
  const { routes, hasRoute, getRoute } = useEnrollRoutes();

  const account = useAccount();

  // fire off the preliminary address search so we can skip
  // the address step if we already have an address match
  useSearchPreliminaryAddress();

  const Welcome = scaffold.enroll.steps.welcome;
  const PickAddress = scaffold.enroll.steps.pickAddress;
  const ConfirmRate = scaffold.enroll.steps.confirmRate;
  const VerifyIdentity = scaffold.enroll.steps.verifyIdentity;

  const PaymentSetup = scaffold.enroll.steps.paymentSetup;
  const OnFilePaymentSetup = scaffold.enroll.steps.onFilePaymentSetup;

  let PaymentSetupComponent = PaymentSetup;
  if (account.partner_payment_method_on_file) {
    PaymentSetupComponent = OnFilePaymentSetup;
  }

  const hasUploadInvoice = Boolean(scaffold.enroll.steps.uploadInvoice);
  const EnrollmentConfirmed = scaffold.enroll.steps.enrollmentConfirmed;
  return (
    <WithEnrollmentHeader>
      <Routes>
        {hasRoute("/welcome") && Welcome && (
          <Route path="/welcome" element={<Welcome />} />
        )}
        {hasUploadInvoice && (
          <Route path="/upload-invoice" element={<UploadInvoice />} />
        )}
        {hasRoute("/service-address/*") && (
          <Route path="/service-address/*" element={<PickAddress />} />
        )}
        {hasRoute("/rate") && <Route path="/rate" element={<ConfirmRate />} />}
        {hasRoute("/verify-identity") && (
          <Route path="/verify-identity" element={<VerifyIdentity />} />
        )}
        {hasRoute("/credit-check") && (
          <Route path="/credit-check" element={<CreditCheck />} />
        )}
        {hasRoute("/setup-payment") && (
          <Route path="/setup-payment" element={<PaymentSetupComponent />} />
        )}
        {hasRoute("/confirm-and-enroll") && (
          <Route path="/confirm-and-enroll" element={<VerifyAndEnroll />} />
        )}
        {hasRoute("/authorize-vehicles/*") && (
          <Route
            path="/authorize-vehicles/*"
            element={<VehicleAuthorization />}
          />
        )}
        {hasRoute("/confirmed") && (
          <Route path="/confirmed" element={<EnrollmentConfirmed />} />
        )}
        <Route path="/*" element={<Navigate to={getRoute(routes[0])} />} />
      </Routes>
    </WithEnrollmentHeader>
  );
};
