import { AddressSearchResult, ServiceEvent } from "@Light/services/lightTypes";
import { useState } from "@Light/utils/state";
import { RequireNoService } from "@Light/components/app/RequireNoService";
import { WithStripe } from "@Light/components/app/WithStripe";
import { BirthDate, EnrollContext } from "./context";
import { EnrollRoute } from "./EnrollRoute";
import { Route, Routes } from "react-router-dom";
import { FAQ } from "./FAQ";
import { useScaffold } from "@Light/scaffold";
import { WithEnrollRoutes } from "./routes";
import { WithPlan } from "@Light/utils/plan";

export type EnrollProps = {
  planUUID?: string;
  serviceActivePath?: string;
};

export const Enroll: React.FC<EnrollProps> = ({
  planUUID,
  serviceActivePath,
}) => {
  const address = useState<AddressSearchResult | null>(null);
  const serviceEvent = useState<ServiceEvent>("switch");
  const birthDate = useState<BirthDate | null>(null);
  const scaffold = useScaffold();
  const WithEnrollmentHeader = scaffold.enroll.withEnrollmentHeader;
  return (
    <RequireNoService redirect={serviceActivePath || "/"}>
      <WithStripe>
        <EnrollContext.Provider value={{ address, serviceEvent, birthDate }}>
          <WithPlan planUUID={planUUID}>
            <div className="box-border h-full overflow-hidden">
              <Routes>
                <Route
                  path="/faq"
                  element={
                    <WithEnrollmentHeader>
                      <FAQ />
                    </WithEnrollmentHeader>
                  }
                />
                <Route
                  path="/*"
                  element={
                    <WithEnrollRoutes>
                      <EnrollRoute />
                    </WithEnrollRoutes>
                  }
                />
              </Routes>
            </div>
          </WithPlan>
        </EnrollContext.Provider>
      </WithStripe>
    </RequireNoService>
  );
};
