import { Button } from "@Light/components/page/Button";
import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import clsx from "clsx";
import { useInvoice } from "./context";
import { useCallback } from "react";
import { useMutation } from "@Light/utils/mutation";
import { PayInvoiceRequest } from "@Light/services/lightTypes";
import { CardDescription, useInvoiceLink } from "./common";

export type PayInvoiceButtonProps = {};

export const PayInvoiceButton: React.FC<PayInvoiceButtonProps> = ({}) => {
  const { invoice } = useInvoice();
  const scaffold = useScaffold();
  const { useGetPaymentMethodQuery, usePayInvoiceMutation } = useLight();
  const paymentMethod = useGetPaymentMethodQuery();
  const payInvoice = useMutation<PayInvoiceRequest, void>(
    usePayInvoiceMutation,
  );
  const invoiceLink = useInvoiceLink(invoice);

  const mutate = useCallback(() => {
    payInvoice.mutate({
      invoiceNumber: invoice.number,
      allow_payment_before_due_date: true,
    });
  }, [payInvoice.mutate, invoice.number]);

  if (paymentMethod.isLoading) {
    return null;
  }

  const Navigate = scaffold.system.navigate;
  if (payInvoice.isSuccess) {
    return <Navigate to={`${invoiceLink}payment-confirmed`} />;
  }

  if (paymentMethod.isError) {
    return (
      <div className="flex flex-col gap-2">
        <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
          You have no payment method configured.
        </div>
        <Button disabled>Pay</Button>
      </div>
    );
  }

  const MutationButton = scaffold.page.mutationButton;
  return (
    <div className="flex flex-col gap-2">
      <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
        By clicking "Pay", your <CardDescription /> will be charged $
        {invoice.total}.
      </div>
      <MutationButton
        mutate={mutate}
        mutation={payInvoice}
        mutateButtonText="Pay"
        errorMessage="Failed to pay invoice. Please try again later."
      />
    </div>
  );
};
