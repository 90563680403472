import { BillingInvoice } from "@Light/services/lightTypes";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";
import { useState } from "@Light/utils/state";

export function paymentDays(
  now: Dayjs,
  invoice: BillingInvoice | undefined,
): number {
  if (!invoice) {
    return 0;
  }
  return dayjs(invoice.payment_due_date).diff(now, "days");
}

export function usePaymentDays(invoice: BillingInvoice | undefined) {
  // We do not want this "now" to change over the life of this component.
  const now = useState(dayjs()).val;
  return useMemo(() => paymentDays(now, invoice), [now, invoice]);
}

export function isPastDue(
  paymentDays: number,
  invoice: BillingInvoice | undefined,
): boolean {
  if (!invoice) {
    return false;
  }
  if (invoice.paid_at) {
    return false;
  }
  if (paymentDays >= 0) {
    return false;
  }
  return true;
}

export function useIsPastDue(invoice: BillingInvoice | undefined) {
  const paymentDays = usePaymentDays(invoice);
  return isPastDue(paymentDays, invoice);
}
