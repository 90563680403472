import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";

export type StatusBoxProps = {
  icon: React.ReactNode;
  className?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
};

export const StatusBox: React.FC<StatusBoxProps> = ({
  icon,
  className,
  title,
  subtitle,
  children,
}) => {
  const scaffold = useScaffold();
  return (
    <div className={clsx("border-2 rounded-lg p-4", className)}>
      <div className={clsx("flex flex-row items-start gap-4")}>
        {icon}
        <div className="flex flex-col w-11/12 gap-4">
          <div className="flex flex-col gap-2">
            {title && (
              <div
                className={clsx(
                  "font-medium",
                  scaffold.page.colors.text.primary,
                )}
              >
                {title}
              </div>
            )}
            {subtitle && (
              <div
                className={clsx("text-sm", scaffold.page.colors.text.secondary)}
              >
                {subtitle}
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
