import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { BillingInvoice } from "./BillingInvoice";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import {
  BillingInvoice as BillingInvoiceType,
  BillingInvoiceDetails,
} from "@Light/services/lightTypes";
import { useParams } from "@Light/utils/context";
import { Route, Routes } from "react-router-dom";
import { InvoiceContext, useInvoice } from "./context";
import { PayInvoice } from "./PayInvoice";
import { PaymentConfirmed } from "./PaymentConfirmed";

const PREVIOUS_ROUTE = "/dash/billing/";

export type BillingInvoiceRouteProps = {
  payInvoiceOnly?: boolean;
  hideBack?: boolean;
  redirect?: React.ReactNode;
};

export const BillingInvoiceRoute: React.FC<BillingInvoiceRouteProps> = ({
  ...props
}) => {
  const scaffold = useScaffold();
  const { invoiceNumber } = useParams();
  if (!invoiceNumber) {
    const Navigate = scaffold.system.navigate;
    return <Navigate to={PREVIOUS_ROUTE} />;
  }
  return <BillingInvoiceRouteB1 invoiceNumber={invoiceNumber} {...props} />;
};

export type BillingInvoiceRouteB1Props = BillingInvoiceRouteProps & {
  invoiceNumber: string;
};

export const BillingInvoiceRouteB1: React.FC<BillingInvoiceRouteB1Props> = ({
  invoiceNumber,
  ...props
}) => {
  const { useGetBillingInvoiceQuery, useGetBillingInvoicesQuery } = useLight();
  const billingInvoice = useGetBillingInvoiceQuery({ invoiceNumber });
  const billingInvoices = useGetBillingInvoicesQuery();

  return (
    <QueryLoader
      queries={[billingInvoice, billingInvoices]}
      errorMessage="Error loading invoice data. Please try again later."
    >
      <InvoiceContext.Provider
        value={{
          invoice: billingInvoice.data as BillingInvoiceDetails,
          invoices: billingInvoices.data?.data as BillingInvoiceType[],
        }}
      >
        <BillingInvoiceRouteB2 {...props} />
      </InvoiceContext.Provider>
    </QueryLoader>
  );
};

export type BillingInvoiceRouteB2Props = BillingInvoiceRouteProps & {};

export const BillingInvoiceRouteB2: React.FC<BillingInvoiceRouteB2Props> = ({
  payInvoiceOnly,
  hideBack,
  redirect,
}) => {
  const { invoice } = useInvoice();
  const scaffold = useScaffold();
  const Navigate = scaffold.system.navigate;
  return (
    <Routes>
      {!payInvoiceOnly && <Route path="/" element={<BillingInvoice />} />}
      <Route
        path="/make-payment"
        element={<PayInvoice hideBack={hideBack} />}
      />
      <Route
        path="/payment-confirmed"
        element={<PaymentConfirmed hideBack={hideBack} />}
      />
      <Route
        path="/*"
        element={
          redirect || (
            <Navigate
              to={`/dash/billing/invoice/${encodeURIComponent(invoice.number)}`}
            />
          )
        }
      />
    </Routes>
  );
};
