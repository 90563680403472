import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useLight } from "@Light/services/light";

export type UpdatePaymentMethodLinkProps = {};

export const UpdatePaymentMethodLink: React.FC<
  UpdatePaymentMethodLinkProps
> = ({}) => {
  const scaffold = useScaffold();
  const Link = scaffold.system.link;
  const { useGetPaymentMethodQuery } = useLight();
  const paymentMethod = useGetPaymentMethodQuery();
  return (
    <Link
      className={clsx(
        "flex flex-row items-center font-medium gap-2",
        scaffold.page.colors.text.secondary,
      )}
      to="/account/payment-method/update"
    >
      <PlusIcon className="w-6 h-6" />
      <div>{paymentMethod.isError ? "Add" : "Update"} payment method</div>
    </Link>
  );
};
