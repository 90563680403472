import { useLight } from "@Light/services/light";
import { useScaffold } from "@Light/scaffold";
import { useHeader } from "@Light/components/page/header/context";
import { Query, QueryLoader } from "@Light/components/app/QueryLoader";
import { BillingContent } from "./BillingContent";

export type BillingProps = {
  hideBack?: boolean;
};

export const Billing: React.FC<BillingProps> = ({ hideBack }) => {
  const { setBackNavigate } = useHeader();

  if (!hideBack) {
    setBackNavigate("/dash/");
  }

  const { useGetBillingInvoicesQuery } = useLight();
  const billingInvoices = useGetBillingInvoicesQuery();

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  return (
    <PageBody className="pt-8">
      <QueryLoader
        queries={[billingInvoices as Query]}
        errorMessage="Error loading invoice data. Please try again later."
      >
        <BillingContent billingInvoices={billingInvoices.data?.data ?? []} />
      </QueryLoader>
    </PageBody>
  );
};
