import { useScaffold } from "@Light/scaffold";
import { useMemo } from "react";
import { BillingInvoice } from "@Light/services/lightTypes";
import { InvoiceGroup } from "./InvoiceGroup";
import clsx from "clsx";
import { useIsPastDue } from "./common";
import { PastDueNotification } from "./PastDueNotification";

export type BillingContentProps = {
  billingInvoices: BillingInvoice[];
};

export const BillingContent: React.FC<BillingContentProps> = ({
  billingInvoices,
}) => {
  const firstInvoice = billingInvoices?.[0];
  const isPastDue = firstInvoice && useIsPastDue(firstInvoice);
  const upcomingInvoices = useMemo(() => {
    return billingInvoices.filter(
      (invoice: BillingInvoice) => !Boolean(invoice.paid_at),
    );
  }, [billingInvoices]);
  const paidInvoices = useMemo(() => {
    return billingInvoices.filter((invoice: BillingInvoice) =>
      Boolean(invoice.paid_at),
    );
  }, [billingInvoices]);

  const scaffold = useScaffold();
  if (upcomingInvoices.length === 0 && paidInvoices.length === 0) {
    return (
      <div
        className={clsx(
          "border rounded-lg p-6",
          "flex justify-center",
          "text-sm",
          scaffold.page.colors.text.secondary,
        )}
      >
        No bills yet
      </div>
    );
  }

  const Divided = scaffold.page.divided;
  return (
    <div className="flex flex-col gap-8">
      {isPastDue && <PastDueNotification />}
      <div className="flex flex-col gap-6">
        <Divided>
          {upcomingInvoices.length > 0 ? (
            <InvoiceGroup title="Upcoming" invoices={upcomingInvoices} />
          ) : null}
          {paidInvoices.length > 0 ? (
            <InvoiceGroup title="All payments" invoices={paidInvoices} />
          ) : null}
        </Divided>
      </div>
    </div>
  );
};
