import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { StatusBox } from "./StatusBox";

export type WarnBoxProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
};

export const WarnBox: React.FC<WarnBoxProps> = ({
  title,
  subtitle,
  children,
}) => {
  const scaffold = useScaffold();
  return (
    <StatusBox
      icon={
        <ExclamationTriangleIcon
          className={clsx("w-1/12", scaffold.page.colors.text.warn)}
        />
      }
      className={clsx(
        scaffold.page.colors.border.warn,
        scaffold.page.colors.background.warn,
      )}
      title={title}
      subtitle={subtitle}
    >
      {children}
    </StatusBox>
  );
};
