import { Navigate, Route, Routes } from "react-router-dom";
import {
  WithConfiguration,
  requireConfiguration,
  useConfiguration,
} from "./WithConfiguration";
import { Login } from "./Login";
import { WithAccount } from "@Light/components/page/account";
import { SessionExpired } from "./SessionExpired";
import { Overlay } from "@Light/components/page/Overlay";
import { Enroll } from "@Light/components/enroll";
import { UpdatePaymentMethod } from "@Light/components/account/PaymentMethod/UpdatePaymentMethod";
import { WithHeader } from "@Light/components/page/header";
import { Close } from "./Close";
import { DocumentsRoute } from "@Light/components/account/Documents/DocumentsRoute";
import { BillingRoute } from "@Light/components/dash/BillingRoute";
import { useLight } from "@Light/services/light";
import { defaultScaffold } from "@Light/scaffold/default";
import { Provider as LightProvider } from "@Light/scaffold/Provider";
import { EnrollmentNotComplete } from "./EnrollmentNotComplete";
import { ServiceAlreadyActive } from "./ServiceAlreadyActive";
import * as Sentry from "@sentry/react";
import { useAnalytics, useAnalyticsSearchParams } from "@Light/app/analytics";
import { PaymentMethodUpdated } from "@Light/components/account/PaymentMethod/PaymentMethodUpdated";
import { BillingInvoiceRoute } from "@Light/components/dash/BillingInvoice/BillingInvoiceRoute";

export const AppRoutes: React.FC = () => {
  useAnalyticsSearchParams();
  return (
    <LightProvider scaffold={defaultScaffold}>
      <Overlay>
        <Sentry.ErrorBoundary
          fallback={<SessionExpired title="Unexpected error" />}
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/session-expired" element={<SessionExpired />} />
            <Route
              path="/*"
              element={
                <WithConfiguration>
                  <ConfigurationRoutes />
                </WithConfiguration>
              }
            />
          </Routes>
        </Sentry.ErrorBoundary>
      </Overlay>
    </LightProvider>
  );
};

export const ConfigurationRoutes: React.FC = () => {
  const configuration = useConfiguration();
  if (!configuration) {
    return (
      <Routes>
        <Route path="/*" element={<Navigate to="/session-expired" />} />
      </Routes>
    );
  }

  return (
    <WithAccount>
      <LoggedInRoutes />
    </WithAccount>
  );
};

export type LoggedInRoutesProps = {};

export const LoggedInRoutes: React.FC<LoggedInRoutesProps> = ({}) => {
  return <ScopedRoutes />;
};

export type ScopedRoutesProps = {};
export const ScopedRoutes: React.FC<ScopedRoutesProps> = ({}) => {
  const configuration = requireConfiguration();
  const { useGetAccountQuery } = useLight();
  const account = useGetAccountQuery();
  const { planUUID } = useAnalytics();

  if (configuration.scope === "enrollment") {
    return (
      <Routes>
        <Route
          path="/service-already-active"
          element={<ServiceAlreadyActive />}
        />
        <Route
          path="/enroll/*"
          element={
            <Enroll
              serviceActivePath="/service-already-active"
              planUUID={planUUID}
            />
          }
        />
        <Route path="/" element={<Navigate to="/enroll/" />} />
        <Route path="/*" element={<Close />} />
      </Routes>
    );
  }

  if (!account.data?.enrollment.is_enrollment_finalized) {
    return <EnrollmentNotComplete />;
  }

  if (configuration.scope === "update-payment-method") {
    return (
      <Routes>
        <Route
          path="/account/payment-method/update"
          element={
            <WithHeader>
              <UpdatePaymentMethod hideBack />
            </WithHeader>
          }
        />
        <Route
          path="/account/payment-method/payment-method-updated"
          element={
            <WithHeader>
              <PaymentMethodUpdated hideBack />
            </WithHeader>
          }
        />
        <Route
          path="/dash/billing/invoice/:invoiceNumber/*"
          element={
            <WithHeader>
              <BillingInvoiceRoute
                payInvoiceOnly
                hideBack
                redirect={<Close />}
              />
            </WithHeader>
          }
        />
        <Route
          path="/"
          element={<Navigate to="/account/payment-method/update" />}
        />
        <Route path="/*" element={<Close />} />
      </Routes>
    );
  }

  if (configuration.scope === "documents") {
    return (
      <Routes>
        <Route
          path="/account/documents/*"
          element={
            <WithHeader>
              <DocumentsRoute hideRootBack />
            </WithHeader>
          }
        />
        <Route path="/" element={<Navigate to="/account/documents/" />} />
        <Route path="/*" element={<Close />} />
      </Routes>
    );
  }

  if (configuration.scope === "billing") {
    return (
      <Routes>
        <Route
          path="/account/payment-method/update"
          element={
            <WithHeader>
              <UpdatePaymentMethod backLink="/dash/billing/" />
            </WithHeader>
          }
        />
        <Route
          path="/account/payment-method/payment-method-updated"
          element={
            <WithHeader>
              <PaymentMethodUpdated
                backLink="/dash/billing/"
                returnLink="/dash/billing/"
              />
            </WithHeader>
          }
        />
        <Route
          path="/dash/billing/*"
          element={
            <WithHeader>
              <BillingRoute hideRootBack showDivider />
            </WithHeader>
          }
        />
        <Route path="/" element={<Navigate to="/dash/billing/" />} />
        <Route path="/*" element={<Close />} />
      </Routes>
    );
  }

  throw new Error(`Invalid scope: ${configuration.scope}`);
};
