import { useScaffold } from "@Light/scaffold";
import { CardDescription, useInvoiceLink } from "./common";
import { useHeader } from "@Light/components/page/header/context";
import { DetailField } from "@Light/components/page/detail/DetailField";
import dayjs from "dayjs";
import { Hyperlink } from "@Light/components/page/Hyperlink";
import clsx from "clsx";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useInvoice } from "./context";

export type PaymentConfirmedProps = {
  hideBack?: boolean;
};

export const PaymentConfirmed: React.FC<PaymentConfirmedProps> = ({
  hideBack,
}) => {
  const { invoice } = useInvoice();
  const invoiceLink = useInvoiceLink(invoice);
  const { setBackNavigate } = useHeader();

  if (!hideBack) {
    setBackNavigate(invoiceLink);
  }

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Link = scaffold.system.link;
  const Button = scaffold.page.button;
  if (!invoice.paid_at) {
    return <QueryLoader />;
  }
  return (
    <PageBody
      title="Thank you for your payment"
      subtitle={<>You've made a payment for invoice #{invoice.number}</>}
      footer={
        <Link to={invoiceLink} className="w-full">
          <Button className="w-full">Return</Button>
        </Link>
      }
    >
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <DetailField label="Amount">${invoice.total}</DetailField>
          <DetailField label="Payment date">
            {dayjs(invoice.paid_at).format("MMM D, YYYY, hh:mm:ss A")}
          </DetailField>
          <DetailField label="Payment method">
            <CardDescription />
          </DetailField>
        </div>
        <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
          If you have any questions, email us at{" "}
          <Hyperlink href={`email:${scaffold.page.supportEmail}`}>
            {scaffold.page.supportEmail}
          </Hyperlink>{" "}
          or call us at{" "}
          <Hyperlink href={`tel:${scaffold.page.supportPhone}`}>
            {scaffold.page.supportPhone}
          </Hyperlink>
          .
        </div>
      </div>
    </PageBody>
  );
};
