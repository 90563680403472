import { CARD_BRANDS } from "@Light/components/account/PaymentMethod/PaymentMethodCard/PaymentMethodCard";
import { useLight } from "@Light/services/light";
import { BillingInvoice } from "@Light/services/lightTypes";
import { useMemo } from "react";

export function useInvoiceLink(invoice: BillingInvoice): string {
  return useMemo(
    () => `/dash/billing/invoice/${encodeURIComponent(invoice.number)}/`,
    [invoice.number],
  );
}

export type CardDescriptionProps = {};

export const CardDescription: React.FC<CardDescriptionProps> = ({}) => {
  const { useGetPaymentMethodQuery } = useLight();
  const paymentMethod = useGetPaymentMethodQuery();
  if (!paymentMethod.isSuccess) {
    return null;
  }
  return (
    <span>
      {CARD_BRANDS[paymentMethod.data.card_brand ?? ""] ?? "card"} ending in{" "}
      {paymentMethod.data.card_last4}
    </span>
  );
};
