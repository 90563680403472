import React from "react";
import { useLight } from "@Light/services/light";
import { useScaffold } from "@Light/scaffold";
import { GenericCard } from "./GenericCard";
import { VisaCard } from "./VisaCard";
import { MasterCardCard } from "./MasterCardCard";
import { AmericanExpressCard } from "./AmericanExpressCard";
import { DiscoverCard } from "./DiscoverCard";

export type PaymentMethodCardProps = {};

export const CARD_BRANDS: Record<string, string> = {
  visa: "Visa",
  mastercard: "MasterCard",
  amex: "American Express",
  discover: "Discover",
  diners: "Diners Club",
  eftpos_au: "Eftpos Australia",
  jcb: "JCB",
  unionpay: "UnionPay",
};

const CARD_ICONS: Record<string, React.FC> = {
  visa: VisaCard,
  mastercard: MasterCardCard,
  amex: AmericanExpressCard,
  discover: DiscoverCard,
};

export const PaymentMethodCard: React.FC<PaymentMethodCardProps> = () => {
  const scaffold = useScaffold();
  const { useGetPaymentMethodQuery } = useLight();
  const paymentMethod = useGetPaymentMethodQuery();
  if (!paymentMethod.data) {
    return null;
  }

  const { card_brand, card_last4, card_exp_month, card_exp_year } =
    paymentMethod.data ?? {};
  const card_brand_display = CARD_BRANDS[card_brand] ?? "Unknown";
  const DetailField = scaffold.page.detail.detailField;
  const CardIcon = CARD_ICONS[card_brand] ?? GenericCard;
  return (
    <div className="rounded-lg border-2 p-4 flex flex-row gap-4 items-start">
      <CardIcon />
      <div className="flex flex-col">
        <DetailField label={`${card_brand_display} ending in ${card_last4}`}>
          Expiry {String(card_exp_month).padStart(2, "0")}/{card_exp_year}
        </DetailField>
      </div>
    </div>
  );
};
