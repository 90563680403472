import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { useMutation } from "@Light/utils/mutation";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useEnrollRoutes } from "./routes";
import {
  CreditCheckRequest,
  CreditCheckResponse,
} from "@Light/services/lightTypes";

export type CreditCheckProps = {};

export const CreditCheck: React.FC<CreditCheckProps> = () => {
  const scaffold = useScaffold();
  const { useCreditCheckMutation } = useLight();
  const creditCheck = useMutation<CreditCheckRequest, CreditCheckResponse>(
    useCreditCheckMutation,
  );
  const { previousRoute, nextRoute } = useEnrollRoutes();

  const { handleSubmit, setValue } = useForm();

  const onSubmit = useCallback(
    handleSubmit((data) => {
      creditCheck.mutate({
        ssn: data.ssn,
        agree_to_terms: true,
      });
    }),
    [handleSubmit, creditCheck.mutate],
  );

  // Local state for the SSN input value and visibility toggle.
  const [ssnValue, setSSNValue] = useState("");
  const [isVisible, setIsVisible] = useState(false); // Default to hidden

  const redirect = nextRoute("/credit-check");
  if (creditCheck.isSuccess && redirect) {
    return <Navigate to={redirect} />;
  }

  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  const Field = scaffold.system.field;
  const Label = scaffold.system.label;
  const Input = scaffold.system.input;
  const Hyperlink = scaffold.page.hyperlink;

  // Formats the input to add dashes automatically.
  const formatSSN = (value: string) => {
    // Strip any non-digit characters and limit to 9 digits.
    const digits = value.replace(/\D/g, "").slice(0, 9);
    if (digits.length > 5) {
      return `${digits.slice(0, 3)}-${digits.slice(3, 5)}-${digits.slice(5)}`;
    } else if (digits.length > 3) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    }
    return digits;
  };

  // Handle changes while typing.
  const handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatSSN(e.target.value);
    setSSNValue(formatted);
    // Update the form's SSN value as long as the format is at least partially correct.
    if (/^\d{0,3}(-?\d{0,2})?(-?\d{0,4})?$/.test(formatted)) {
      setValue("ssn", formatted);
    }
  };

  // Handle focus to show SSN
  const handleSSNFocus = () => {
    setIsVisible(true);
  };

  // Hide SSN when input loses focus
  const handleSSNBlur = () => {
    setIsVisible(false);
  };

  // Handle paste events to reformat the input immediately.
  const handleSSNPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData("Text");
    const formatted = formatSSN(pastedData);
    e.preventDefault();
    setSSNValue(formatted);
    setValue("ssn", formatted);
  };

  return (
    <form onSubmit={onSubmit}>
      <PageBody
        backLink={previousRoute("/credit-check")}
        title="Credit Verification"
        subtitle="We need to verify your credit to proceed with enrollment."
        progress="40%"
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <Field className="flex flex-col gap-1 w-full">
              <Label>Social Security Number</Label>
              <div className="relative">
                <Input
                  type={isVisible ? "text" : "password"}
                  value={ssnValue}
                  onChange={handleSSNChange}
                  onFocus={handleSSNFocus}
                  onBlur={handleSSNBlur}
                  onPaste={handleSSNPaste}
                  placeholder="XXX-XX-XXXX"
                  maxLength={11} // 9 digits + 2 dashes
                />
              </div>
              <p className="text-xs text-gray-500 mt-1">Format: XXX-XX-XXXX</p>
            </Field>
            <div className="text-xs text-gray-600 mt-4">
              By selecting "Accept and continue", you authorize Light under the
              Fair Credit Reporting Act (FCRA) to obtain your credit report
              solely to determine eligibility for electric service. This is a
              soft inquiry and will not impact your credit score.
              <br />
              <br />
              Your information will be handled securely per our{" "}
              <Hyperlink href="https://light.dev/privacy">
                Privacy Policy
              </Hyperlink>
              . For assistance or to update your contact information, contact{" "}
              <Hyperlink href={`mailto:${scaffold.page.supportEmail}`}>
                {scaffold.page.supportEmail}
              </Hyperlink>
              .
            </div>
          </div>
          <MutationButton
            mutation={creditCheck}
            mutateButtonText="Accept and continue"
            errorMessage="Failed to submit credit check. Please try again later"
          />
        </div>
      </PageBody>
    </form>
  );
};
