import { useMutation } from "@Light/utils/mutation";
import { useLight } from "@Light/services/light";

export type UploadInvoiceProps = {};

export const UploadInvoice: React.FC<UploadInvoiceProps> = ({}) => {
  const {
    useUploadInvoiceMutation,
    useGetComparisonInvoiceQuery,
    useGetComparisonInvoiceSimpleCompareQuery,
  } = useLight();
  const uploadInvoice = useMutation<File, void>(useUploadInvoiceMutation);

  const comparisonInvoice = useGetComparisonInvoiceQuery();

  const simpleCompare = useGetComparisonInvoiceSimpleCompareQuery();

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.type !== "application/pdf") {
      alert("Please upload a PDF file only");
      event.target.value = ""; // Reset input
      return;
    }

    try {
      uploadInvoice.mutate(file);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  return (
    <div className="flex flex-col gap-2 mt-4">
      <input
        type="file"
        accept=".pdf,application/pdf"
        onChange={handleFileUpload}
        disabled={uploadInvoice.isLoading}
      />
      {uploadInvoice.isLoading && <span>Uploading...</span>}
      {uploadInvoice.isError && (
        <span className="error">Upload failed. Please try again.</span>
      )}
      {comparisonInvoice.data && (
        <div className="mt-4 flex flex-col gap-2 items-center">
          <a
            className="text-blue-500"
            href={comparisonInvoice.data.file}
            target="_blank"
            rel="noreferrer"
          >
            View Previously Uploaded Invoice
          </a>
        </div>
      )}
      {simpleCompare.data && (
        <div className="mt-4 p-4 border rounded-lg">
          {simpleCompare.data.processing_error ? (
            <div className="text-red-600">
              {simpleCompare.data.processing_error}
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <h3 className="font-semibold">Invoice Comparison</h3>
              <div className="grid grid-cols-2 gap-1 text-sm">
                <span className="text-gray-600">Current Plan Cost:</span>
                <span>
                  ${simpleCompare.data.parsed_invoice_data?.total_dollars}
                </span>
                <span className="text-gray-600">Usage:</span>
                <span>
                  {simpleCompare.data.parsed_invoice_data?.total_kwh} kWh
                </span>
                <span className="text-gray-600">New Plan Cost:</span>
                <span>
                  ${simpleCompare.data.plan_comparison?.total_dollars}
                </span>
                <span className="text-gray-600">Plan Name:</span>
                <span>{simpleCompare.data.plan_comparison?.name}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
