import { useIsPastDue } from "../Billing/common";
import { useInvoice } from "./context";
import { useMemo } from "react";
import dayjs from "dayjs";
import { WarnBox } from "@Light/components/page/errors/WarnBox";
import { ErrorBox } from "@Light/components/page/errors/ErrorBox";
import { useLight } from "@Light/services/light";
import { CardDescription } from "./common";

export type PayInvoiceNotificationProps = {};

export const PayInvoiceNotification: React.FC<
  PayInvoiceNotificationProps
> = ({}) => {
  const { useGetPaymentMethodQuery } = useLight();
  const paymentMethod = useGetPaymentMethodQuery();
  const { invoice } = useInvoice();
  const isPastDue = useIsPastDue(invoice);
  const paymentDueDate = useMemo(
    () => dayjs(invoice.payment_due_date),
    [invoice.invoice_date],
  );
  if (paymentMethod.isLoading) {
    return null;
  }

  if (paymentMethod.isError) {
    return (
      <div className="flex flex-col gap-4">
        <ErrorBox
          subtitle={
            <>
              You do not have a payment method configured. Please click the "Add
              payment method" link below to add a payment method.
            </>
          }
        />
      </div>
    );
  }

  if (isPastDue) {
    return (
      <div className="flex flex-col gap-4">
        <ErrorBox
          subtitle={
            <>
              This invoice is past due because we failed to charge your payment
              method.
            </>
          }
        />
      </div>
    );
  }

  return (
    <WarnBox
      subtitle={
        <>
          This invoice will automatically be charged to your <CardDescription />{" "}
          on{" "}
          <span className="font-medium">
            {paymentDueDate.format("MMM D, YYYY")}
          </span>
          .
        </>
      }
    />
  );
};
