import { useMemo } from "react";
import { useScaffold } from "@Light/scaffold";

export type Query = {
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  data?: any;
  error?: any;
};

export type QueryLoaderProps = {
  queries?: Query[];
  allowError?: boolean;
  error?: React.ReactNode;
  errorMessage?: string;
  children?: React.ReactNode;
};

export const QueryLoader: React.FC<QueryLoaderProps> = ({
  queries,
  allowError,
  error,
  errorMessage,
  children,
}) => {
  const scaffold = useScaffold();
  const queryLoading = useMemo(() => {
    return (
      !queries ||
      queries.some((query) =>
        Boolean(query.isLoading || (!query.isSuccess && !query.isError)),
      )
    );
  }, [queries]);
  const hasError = useMemo(() => {
    return queries && queries.some((query) => Boolean(query.isError));
  }, [queries]);

  if (queryLoading) {
    const Spinner = scaffold.system.spinner;
    return (
      <div className="flex items-center justify-center h-40">
        <Spinner className="w-8 h-8" />
      </div>
    );
  }

  if (hasError && !allowError) {
    if (error) {
      return error;
    }
    const Field = scaffold.system.field;
    const ErrorMessage = scaffold.system.errorMessage;
    return (
      <Field>
        <ErrorMessage>
          {errorMessage ||
            "There was an unexpected error. Please try again later"}
        </ErrorMessage>
      </Field>
    );
  }

  return children;
};
