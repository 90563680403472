import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useInvoice } from "./context";
import { useInvoiceLink } from "./common";

export type MakePaymentLinkProps = {};

export const MakePaymentLink: React.FC<MakePaymentLinkProps> = ({}) => {
  const { invoice } = useInvoice();
  const invoiceLink = useInvoiceLink(invoice);
  const scaffold = useScaffold();
  const Link = scaffold.system.link;
  if (invoice.paid_at) {
    return null;
  }
  return (
    <Link to={`${invoiceLink}make-payment`}>
      <div
        className={clsx("flex flex-row items-center gap-1 font-medium text-sm")}
      >
        <div>Make payment</div>
        <ArrowRightIcon className="w-4 h-4" />
      </div>
    </Link>
  );
};
