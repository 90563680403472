import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import {
  AddressSearchResult,
  ESIIDSearchRequest,
} from "@Light/services/lightTypes";
import { Mutation, useMutation } from "@Light/utils/mutation";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useEnroll } from "../context";
import { useNavigate } from "@Light/utils/context";
import { InputButton } from "@Light/components/system/Input";
import { useEnrollRoutes } from "../routes";

export type CheckESIIDProps = {};
export const CheckESIID: React.FC<CheckESIIDProps> = (
  props: CheckESIIDProps,
) => {
  const { useSearchESIIDMutation } = useLight();
  return (
    <CheckESIIDB1
      {...props}
      searchESIID={useMutation(useSearchESIIDMutation)}
    />
  );
};

export type CheckESIIDB1props = CheckESIIDProps & {
  searchESIID: Mutation<ESIIDSearchRequest, AddressSearchResult>;
};

export const CheckESIIDB1: React.FC<CheckESIIDB1props> = ({ searchESIID }) => {
  const scaffold = useScaffold();
  const { handleSubmit, register } = useForm();
  const { address } = useEnroll();
  const { getRoute } = useEnrollRoutes();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchESIID.data) {
      return;
    }
    address.setVal(searchESIID.data);
    navigate("/enroll/service-address/service-event");
  }, [searchESIID.data, address.setVal]);

  const onSubmit = useCallback(
    handleSubmit((body: any) => {
      searchESIID.mutate(body as ESIIDSearchRequest);
    }),
    [handleSubmit, searchESIID.mutate],
  );

  const PageBody = scaffold.page.pageBody;
  const Field = scaffold.system.field;
  const Label = scaffold.system.label;
  const Input = scaffold.system.input;
  const ErrorMessage = scaffold.system.errorMessage;
  return (
    <form onSubmit={onSubmit} className="h-full">
      <PageBody
        backLink={getRoute("/service-address/")}
        title="Search by ESI ID"
        subtitle={
          <div className="flex flex-col gap-2">
            <div>
              Your ESI ID (Electric Service Identifier) is a 17 to 22 digit
              number that can be found on your electricity bill.
            </div>
            <div>
              Enter your ESI ID below to see if you are eligible for{" "}
              {scaffold.page.companyName} plans in your area.
            </div>
          </div>
        }
      >
        <div className="flex flex-col gap-20">
          <div className="flex flex-col gap-6 -mt-4">
            <Field>
              <Label>ESI ID</Label>
              <Input
                {...register("esi_id", { required: true })}
                isError={searchESIID.isError}
                isLoading={searchESIID.isLoading}
                inputButton={
                  <InputButton onClick={onSubmit}>Search</InputButton>
                }
                data-record="true"
              />
              {searchESIID.isError ? (
                <ErrorMessage>No results found.</ErrorMessage>
              ) : (
                <div className="h-[60px]" />
              )}
            </Field>
          </div>
        </div>
      </PageBody>
    </form>
  );
};
