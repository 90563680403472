import { Elements } from "@stripe/react-stripe-js";
import { useStripe } from "@Light/app/stripe";
import { useScaffold } from "@Light/scaffold";
import {
  PaymentSession,
  usePaymentSession,
} from "@Light/components/page/payment/PaymentSession";
import { useUpdatePaymentMethodForm } from "@Light/components/page/form/UpdatePaymentMethod";
import { useEffect, useState } from "react";
import { useAccount } from "@Light/components/page/account";
import { useLight } from "@Light/services/light";
import { useMutation } from "@Light/utils/mutation";
import { useEnrollRoutes } from "./routes";

export type PaymentSetupProps = {};

export const PaymentSetup: React.FC<PaymentSetupProps> = () => {
  return (
    <PaymentSession>
      <PaymentSetupWithSession />
    </PaymentSession>
  );
};

export const PaymentSetupWithSession: React.FC = () => {
  const stripe = useStripe();
  const paymentSession = usePaymentSession();
  return (
    <Elements
      stripe={stripe.stripePromise}
      options={{ clientSecret: paymentSession.client_secret }}
    >
      <PaymentSessionWithElements />
    </Elements>
  );
};

export const PaymentSessionWithElements: React.FC = () => {
  const [failedStripeAttempts, setFailedStripeAttempts] = useState(0);
  const account = useAccount();
  const { previousRoute, nextRoute } = useEnrollRoutes();

  const scaffold = useScaffold();
  const { onSubmit, mutation, form, stripeError } =
    useUpdatePaymentMethodForm(false);

  const collectPaymentAfterEnrollment =
    scaffold.enroll.collectPaymentAfterEnrollment;

  const { useFinalizeEnrollmentMutation } = useLight();
  const finalizeEnrollment = useMutation(useFinalizeEnrollmentMutation);

  // keep track of failed stripe attempts so we can show a different error message
  useEffect(() => {
    if (stripeError) {
      setFailedStripeAttempts((prev) => prev + 1);
    }
  }, [stripeError, setFailedStripeAttempts]);

  useEffect(() => {
    if (!mutation.isSuccess) {
      return;
    }
    if (!account.enrollment?.has_payment_method) {
      return;
    }
    if (!collectPaymentAfterEnrollment) {
      return;
    }
    finalizeEnrollment.mutate();
  }, [
    mutation.isSuccess,
    account.enrollment?.has_payment_method,
    collectPaymentAfterEnrollment,
    finalizeEnrollment.mutate,
  ]);

  const redirect = nextRoute("/setup-payment");
  if (mutation.isSuccess && redirect) {
    const Navigate = scaffold.system.navigate;
    return <Navigate to={redirect} />;
  }

  let fullStripeErrorMessage = stripeError;
  if (failedStripeAttempts > 1) {
    fullStripeErrorMessage += ` You can also contact support at ${scaffold.page.supportEmail} for help.`;
  }

  let title = "Add a payment method";
  let subtitle = "You won't be charged until your next bill is due.";
  let buttonText = "Add & continue";
  let progress = "50%";
  if (collectPaymentAfterEnrollment) {
    title = "Set up autopay";
    subtitle =
      "Add a payment method for autopay to complete your enrollment. You won't be charged until your next bill is due.";
    buttonText = "Add & complete enrollment";
    progress = "75%";
  }

  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  return (
    <form onSubmit={onSubmit}>
      <PageBody
        backLink={previousRoute("/setup-payment")}
        title={title}
        subtitle={subtitle}
        progress={progress}
      >
        <div className="flex flex-col gap-6">
          {form}
          <MutationButton
            mutation={mutation}
            mutateButtonText={buttonText}
            mutate={onSubmit}
            errorMessage={
              fullStripeErrorMessage ??
              "Error submitting payment information. Please try again later."
            }
          />
        </div>
      </PageBody>
    </form>
  );
};
