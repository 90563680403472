import { createContext, useContext } from "react";
import {
  BillingInvoice as BillingInvoiceType,
  BillingInvoiceDetails,
} from "@Light/services/lightTypes";

type Context = {
  invoice: BillingInvoiceDetails;
  invoices: BillingInvoiceType[];
};

export const InvoiceContext = createContext<Context | undefined>(undefined);

export function useInvoice(): Context {
  const context = useContext(InvoiceContext);
  if (context === undefined) {
    throw new Error("InvoiceContext is not configured properly.");
  }
  return context;
}
