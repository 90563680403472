import { useScaffold } from "@Light/scaffold";
import { BillingInvoiceDetails } from "@Light/services/lightTypes";
import { ChargeGroup } from "./ChargeGroup";
import { DollarAmount } from "./DollarAmount";
import clsx from "clsx";

export type InvoiceChargesProps = {
  invoice: BillingInvoiceDetails;
};

export const InvoiceCharges: React.FC<InvoiceChargesProps> = ({ invoice }) => {
  const scaffold = useScaffold();
  const Divided = scaffold.page.divided;
  return (
    <Divided>
      <Divided>
        {invoice.charge_groups.map((chargeGroup) => (
          <ChargeGroup key={chargeGroup.key} chargeGroup={chargeGroup} />
        ))}
      </Divided>
      <div className="flex flex-row justify-between w-full">
        <div className="text-lg font-medium">Total amount payable</div>
        <div className="flex flex-row">
          <DollarAmount
            className={clsx(
              "text-lg font-medium",
              scaffold.page.colors.text.primary,
            )}
            amount={invoice.total}
          />
          <div className="w-6" />
        </div>
      </div>
    </Divided>
  );
};
