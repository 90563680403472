import { ErrorBox } from "@Light/components/page/errors/ErrorBox";
import { useScaffold } from "@Light/scaffold";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

export type PastDueNotificationProps = {};

export const PastDueNotification: React.FC<PastDueNotificationProps> = ({}) => {
  const scaffold = useScaffold();
  const Link = scaffold.system.link;
  return (
    <ErrorBox
      title="Your payment didn't go through"
      subtitle={
        <>
          Please update your payment info to keep your service running smoothly
        </>
      }
    >
      <Link to="/account/payment-method/update">
        <div
          className={clsx(
            "flex flex-row items-center gap-1 font-medium text-sm",
          )}
        >
          <div>Update payment method</div>
          <ArrowRightIcon className="w-4 h-4" />
        </div>
      </Link>
    </ErrorBox>
  );
};
