import { useScaffold } from "@Light/scaffold";
import { useEnroll } from "../../context";
import { ServiceEventButton } from "./ServiceEventButton";
import { useCallback } from "react";
import { clsx } from "clsx";
import { useNavigate } from "@Light/utils/context";
import { ServiceEvent as ServiceEventType } from "@Light/services/lightTypes";
import { useEnrollRoutes } from "../../routes";
import { useRef } from "react";
import { Button } from "@Light/components/page/Button";

export type ServiceEventProps = {};

export const ServiceEvent: React.FC<ServiceEventProps> = ({}) => {
  const { address, serviceEvent } = useEnroll();
  const navigate = useNavigate();
  const { getRoute, nextRoute } = useEnrollRoutes();
  const redirect = nextRoute("/service-address/*");

  const onClick = useCallback(() => {
    if (!redirect) {
      throw new Error("Cannot redirect from service event selection");
    }
    navigate(redirect);
  }, [redirect, navigate]);

  const onSelectEvent = useCallback(
    (event: ServiceEventType) => {
      serviceEvent.setVal(event);
      buttonRef.current?.focus();
    },
    [serviceEvent.setVal],
  );

  const buttonRef = useRef<HTMLButtonElement>(null);

  if (!address.val) {
    throw new Error("address must be set to render ServiceEvent");
  }

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Link = scaffold.system.link;
  const ServiceAddressField = scaffold.page.detail.serviceAddressField;
  const hasOffer = Boolean(scaffold.enroll.steps.offer);
  const Hyperlink = scaffold.page.hyperlink;

  const isSunnova = scaffold.page.companyName
    .toLowerCase()
    .startsWith("sunnova");

  const legalEntityName =
    scaffold.page.legalEntityName || scaffold.page.companyName;

  return (
    <PageBody
      title="Let's build your plan"
      subtitle="Is this address correct?"
      footer={
        <div className="flex flex-col gap-4">
          {isSunnova && (
            <div className="text-xs text-gray-600">
              <span>By proceeding, you agree to</span>
              <ol className="list-decimal list-outside pl-6 mt-1">
                <li>
                  <span>Sunnova's </span>
                  <Hyperlink
                    href={scaffold.page.termsOfServiceUrl}
                    className="font-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </Hyperlink>
                </li>
                <li>
                  <Hyperlink
                    href="https://www.sunnova.com/legal/e-sign-consent"
                    className="font-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Electronic Disclosure Consent
                  </Hyperlink>
                </li>
                <li>
                  Receive recurring marketing and other calls, texts, and
                  prerecorded messages from Sunnova and its dealers at the
                  number I provide, even if I am on a "Do Not Call" list
                  (consent not required to make a purchase, msg & data rates
                  apply, reply STOP to opt-out of texts or HELP for help)
                </li>
                <li>
                  <span>The </span>
                  <Hyperlink
                    href="https://www.sunnova.com/legal/sunnova-terms-of-use"
                    className="font-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </Hyperlink>
                  <span> (including arbitration provision); and the </span>
                  <Hyperlink
                    href="https://www.sunnova.com/legal/privacy-policy"
                    className="font-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Hyperlink>
                  <span> and </span>
                  <Hyperlink
                    href="https://www.sunnova.com/legal/california-privacy-policy"
                    className="font-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    California Privacy Notice
                  </Hyperlink>
                  .
                </li>
              </ol>
            </div>
          )}
          {!hasOffer &&
            !isSunnova &&
            Boolean(scaffold.page.termsOfServiceUrl) && (
              <div
                className={clsx("text-sm", scaffold.page.colors.text.tertiary)}
              >
                <span>By proceeding, you agree to {legalEntityName}'s</span>{" "}
                <span>
                  <Hyperlink
                    href={scaffold.page.termsOfServiceUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </Hyperlink>
                  .
                </span>
              </div>
            )}
          <Button
            className={clsx(
              "focus:ring-4 focus:ring-blue-500 focus:border-blue-500",
            )}
            onClick={onClick}
            ref={buttonRef}
          >
            See your plan
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <ServiceAddressField {...address.val} />
            <Link to={getRoute("/service-address/")}>
              <Button
                buttonType="clear"
                className={clsx("text-sm", scaffold.page.colors.text.secondary)}
              >
                Edit
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <ServiceEventButton
            isSelected={serviceEvent.val === "switch"}
            onChange={useCallback(
              () => onSelectEvent("switch"),
              [onSelectEvent],
            )}
          >
            I already live at this address.
          </ServiceEventButton>
          <ServiceEventButton
            isSelected={serviceEvent.val === "move_in"}
            onChange={useCallback(
              () => onSelectEvent("move_in"),
              [onSelectEvent],
            )}
          >
            I'm moving to this address.
          </ServiceEventButton>
        </div>
      </div>
    </PageBody>
  );
};
